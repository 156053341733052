import {qspost} from '@/plugins/axios'

/*
 * 获得当前机构现行的模板
 * @constructor jack
 * */
export const getOrgWordTemplateList = () => qspost('/index/Org/getOrgWordTemplateList', {})

/*
 * 更新当前机构现行的模板
 * @constructor jack
 * */
export const updateWordTemplate = (id,file) => qspost('/index/Org/updateWordTemplate', {id,file})
