<template>
  <div class="wrapper flex align-start justify-between padding-lr-sm" v-if="quanzhioffer">
    <div class="flex-sub">

      <el-divider content-position="center">
        <h2 class="title">入职模版</h2>
      </el-divider>

      <div class="item-title">
        <el-divider content-position="left" class="margin-top">全职</el-divider>
      </div>
      <div class="flex align-center justify-between flex-wrap ">
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">Offer Letter</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(quanzhioffer.id)">更新</el-button>
            <el-link :href="openFile + (quanzhioffer.new_word_url?quanzhioffer.new_word_url:quanzhioffer.word_url) + '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">入职通知</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(quanzhiruzhitongzhi.id)">更新</el-button>
            <el-link :href="openFile + (quanzhiruzhitongzhi.new_word_url?quanzhiruzhitongzhi.new_word_url:quanzhiruzhitongzhi.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">劳务合同</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(quanzhilaowuhetong.id)">更新</el-button>
            <el-link :href="openFile + (quanzhilaowuhetong.new_word_url?quanzhilaowuhetong.new_word_url:quanzhilaowuhetong.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">保密协议</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(quanzhibaomixieyi.id)">更新</el-button>
            <el-link :href="openFile + (quanzhibaomixieyi.new_word_url?quanzhibaomixieyi.new_word_url:quanzhibaomixieyi.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
      </div>
      <div class="item-title">
        <el-divider content-position="left" class="margin-top">实习生</el-divider>
      </div>
      <div class="flex align-center justify-between flex-wrap ">
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">Offer Letter</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(shixioffer.id)">更新</el-button>
            <el-link :href="openFile + (shixioffer.new_word_url?shixioffer.new_word_url:shixioffer.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">入职通知</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(shixiruzhitongzhi.id)">更新</el-button>
            <el-link :href="openFile + (shixiruzhitongzhi.new_word_url?shixiruzhitongzhi.new_word_url:shixiruzhitongzhi.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">实习协议</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(shixixieyi.id)">更新</el-button>
            <el-link :href="openFile + (shixixieyi.new_word_url?shixixieyi.new_word_url:shixixieyi.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item" style="visibility: hidden"></div>

      </div>
      <div class="item-title">
        <el-divider content-position="left" class="margin-top">兼职</el-divider>
      </div>
      <div class="flex align-center justify-between flex-wrap ">
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">Offer Letter</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(jianzhioffer.id)">更新</el-button>
            <el-link :href="openFile + (jianzhioffer.new_word_url?jianzhioffer.new_word_url:jianzhioffer.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">入职通知</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(jianzhiruzhitongzhi.id)">更新</el-button>
            <el-link :href="openFile + (jianzhiruzhitongzhi.new_word_url?jianzhiruzhitongzhi.new_word_url:jianzhiruzhitongzhi.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">兼职协议</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(jianzhixieyi.id)">更新</el-button>
            <el-link :href="openFile + (jianzhixieyi.new_word_url?jianzhixieyi.new_word_url:jianzhixieyi.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item" style="visibility: hidden"></div>
      </div>
    </div>


    <el-divider direction="vertical" class="divider h-730"></el-divider>


    <div class="flex-sub">
      <el-divider content-position="center">
        <h2 class="title">离职模版</h2>
      </el-divider>

      <div class="item-title">
        <el-divider content-position="left" class="margin-top">全职</el-divider>
      </div>
      <div class="flex align-center justify-between flex-wrap ">
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">离职协议</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(quanzhilizhixieyi.id)">更新</el-button>
            <el-link :href="openFile + (quanzhilizhixieyi.new_word_url?quanzhilizhixieyi.new_word_url:quanzhilizhixieyi.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">离职交接表</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(quanzhilizhijiaojie.id)">更新</el-button>
            <el-link :href="openFile + (quanzhilizhijiaojie.new_word_url?quanzhilizhijiaojie.new_word_url:quanzhilizhijiaojie.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">薪资核算单</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(quanzhixinzihesuan.id)">更新</el-button>
            <el-link :href="openFile + (quanzhixinzihesuan.new_word_url?quanzhixinzihesuan.new_word_url:quanzhixinzihesuan.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">离职证明</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(quanzhilizhizhengming.id)">更新</el-button>
            <el-link :href="openFile + (quanzhilizhizhengming.new_word_url?quanzhilizhizhengming.new_word_url:quanzhilizhizhengming.word_url)+ '?v=' + timer"
                     target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
      </div>
      <div class="item-title">
        <el-divider content-position="left" class="margin-top">实习生</el-divider>
      </div>
      <div class="flex align-center justify-between flex-wrap ">
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">解除实习协议</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(shixijiechuxieyi.id)">更新</el-button>
            <el-link :href="openFile + (shixijiechuxieyi.new_word_url?shixijiechuxieyi.new_word_url:shixijiechuxieyi.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">实习证明</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(shixilizhizhengming.id)">更新</el-button>
            <el-link :href="openFile + (shixilizhizhengming.new_word_url?shixilizhizhengming.new_word_url:shixilizhizhengming.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>
        <div class="template-item" style="visibility: hidden"></div>
        <div class="template-item" style="visibility: hidden"></div>
      </div>
      <div class="item-title">
        <el-divider content-position="left" class="margin-top">兼职</el-divider>
      </div>
      <div class="flex align-center justify-between flex-wrap ">
        <div class="template-item">
          <div class="status">模版</div>
          <img src="../../../assets/img/word.png" class="item-img">
          <div class="item-name">兼职终止通知</div>
          <div class="flex align-center justify-around">
            <el-button class="template-btn" size="mini" type="primary" @click="chooseFile(jianzhizhongzhixieyi.id)">更新</el-button>
            <el-link :href="openFile + (jianzhizhongzhixieyi.new_word_url?jianzhizhongzhixieyi.new_word_url:jianzhizhongzhixieyi.word_url)+ '?v=' + timer" target="_blank">
              <el-button class="template-btn" size="mini" type="warning">下载</el-button>
            </el-link>
          </div>
        </div>

      </div>
    </div>


    <el-upload
        style="display: none;"
        ref="upBtn"
        :action="actions"
        :data="{token}"
        :limit="1"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :on-success="handleSuccess"
    >
      <i class="btnbtn" ref="upload"></i>
    </el-upload>







  </div>
</template>

<script>


import {getOrgWordTemplateList, updateWordTemplate} from "@/api/template";
import {openFile, TemplateFile} from "@/config";

export default {
  data() {
    return {
      openFile:openFile,
      timer:'',
      quanzhioffer: null,  // 全职邀请offer发送
      quanzhiruzhitongzhi: null,  // 全职入职通知
      quanzhilaowuhetong: null,  // 全职劳动合同
      quanzhibaomixieyi: null,  // 全职保密协议

      shixioffer: null,  // 实习邀请offer发送
      shixiruzhitongzhi: null,  // 实习入职通知
      shixixieyi: null,  // 实习协议

      jianzhioffer: null,  // 兼职邀请offer发送
      jianzhiruzhitongzhi: null,  // 兼职入职通知
      jianzhixieyi: null,  // 兼职协议

      quanzhilizhixieyi: null,  // 全职离职协议
      quanzhilizhijiaojie: null,  // 全职离职交接表
      quanzhixinzihesuan: null,  // 全职离职薪资核算单
      quanzhilizhizhengming: null,  // 全职离职证明

      shixijiechuxieyi: null,  // 实习离职解除实习协议
      shixilizhizhengming: null,  // 实习离职证明

      jianzhizhongzhixieyi: null,  // 兼职离职终止协议

      upType: 1,
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return TemplateFile
    }
  },
  created() {
    this.$emit("setIndex", this.$route.path, "模版管理", false);
    this.getOrgWordTemplateList()
    this.timer = new Date().getTime()
  },
  components: {},
  methods: {

    // 上传文件
    chooseFile(num) {
      this.upId = num
      document.querySelector('.btnbtn').click()
    },

    beforeAvatarUpload(file) {
      const isWrap = ( file.type.indexOf('docx') !== 0);
      if (!isWrap) {
        this.$message.error('请上传word文件!');
      }
      return isWrap;
    },
    handleSuccess(file) {
      if (file.status === 200) {
        this.upTemplater(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upTemplater(url) {
      await updateWordTemplate(this.upId, url)
      this.$message.success('更新成功')
      await this.getOrgWordTemplateList()
    },


    async getOrgWordTemplateList() {
      const res = await getOrgWordTemplateList()
      this.quanzhioffer = res.data.quanzhioffer
      this.jianzhioffer = res.data.jianzhioffer
      this.shixioffer = res.data.shixioffer
      this.quanzhiruzhitongzhi = res.data.quanzhiruzhitongzhi
      this.jianzhiruzhitongzhi = res.data.jianzhiruzhitongzhi
      this.quanzhilaowuhetong = res.data.quanzhilaowuhetong
      this.shixiruzhitongzhi = res.data.shixiruzhitongzhi
      this.quanzhibaomixieyi = res.data.quanzhibaomixieyi
      this.jianzhixieyi = res.data.jianzhixieyi
      this.shixixieyi = res.data.shixixieyi
      this.quanzhilizhixieyi = res.data.quanzhilizhixieyi
      this.quanzhilizhijiaojie = res.data.quanzhilizhijiaojie
      this.quanzhixinzihesuan = res.data.quanzhixinzihesuan
      this.quanzhilizhizhengming = res.data.quanzhilizhizhengming
      this.shixijiechuxieyi = res.data.shixijiechuxieyi
      this.shixilizhizhengming = res.data.shixilizhizhengming
      this.jianzhizhongzhixieyi = res.data.jianzhizhongzhixieyi
    }
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.wrapper {
  .title {
    color: #00a2d4;
  }

  .item-title {
    width: 110px;
  }

  .template-item {
    width: 24%;
    height: 160px;
    position: relative;
    overflow: hidden;
    background: #f5f5f5;
    border-radius: 10px;

    .status {
      position: absolute;
      top: 10px;
      right: -25px;
      height: 25px;
      line-height: 25px;
      padding: 0 30px;
      background: #9ea1a3;
      text-align: center;
      z-index: 1;
      transform: rotate(45deg);
      color: #fff;
    }

    .item-img {
      display: block;
      width: 30px;
      margin: 30px auto 10px;
    }

    .item-name {
      text-align: center;
      font-size: 18px;
      line-height: 36px;
      margin-bottom: 10px;
    }

    .template-btn {
      width: 50px;
      padding: 8px 0;
      margin: 0;
    }


  }
}
</style>
